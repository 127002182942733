var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { normalizeObject, dataLayerPush, dataLayerPushEcommerce, toEhashAsync } from "../shared/utils";
var dwLoadEventHandler = function (e) {
    var dwData = Object.assign({}, e === null || e === void 0 ? void 0 : e.detail);
    var parsedInputData = parseInputData(dwData);
    var items = mapProductsToItems(dwData["products"]);
    var productName = getProductName(dwData["products"]);
    var donateWidgetEventData = normalizeObject(__assign(__assign({}, parsedInputData), { event: "view_item", event_label: productName, event_action: "checkout_donate_widget_loaded", non_interaction: true, std_pdp_view: 1, standard_interactions: 1, ecommerce: {
            items: items,
        } }));
    dataLayerPushEcommerce(donateWidgetEventData);
};
var dwGiveNowClickHandlere = function (e) {
    var dwData = Object.assign({}, e === null || e === void 0 ? void 0 : e.detail);
    var parsedInputData = parseInputData(dwData);
    var items = mapProductsToItems(dwData["products"]);
    var productName = getProductName(dwData["products"]);
    var donateWidgetEventData = normalizeObject(__assign(__assign({}, parsedInputData), { event: "add_to_cart", event_label: productName, non_interaction: false, hi_add_to_carts: 1, high_intent_interactions: 1, ecommerce: {
            items: items,
        } }));
    dataLayerPushEcommerce(donateWidgetEventData);
};
var monthlyUpsellClickHandler = function (e) {
    var dwData = Object.assign({}, e === null || e === void 0 ? void 0 : e.detail);
    var parsedInputData = parseInputData(dwData);
    var items = mapProductsToItems(dwData["products"]);
    var donateWidgetEventData = normalizeObject(__assign(__assign({}, parsedInputData), { event: "checkout_donate_widget_monthly_upsell", event_label: dwData["page_url"], non_interaction: false, ecommerce: {
            items: items,
        } }));
    dataLayerPushEcommerce(donateWidgetEventData);
};
var paymentOptionsViewHandler = function (e) {
    var dwData = Object.assign({}, e === null || e === void 0 ? void 0 : e.detail);
    var parsedInputData = parseInputData(dwData);
    var items = mapProductsToItems(dwData["products"]);
    var donateWidgetEventData = normalizeObject(__assign(__assign({}, parsedInputData), { event: "begin_checkout", hi_checkout_start: 1, high_intent_interactions: 1, step_number: 1, ecommerce: {
            currency: "USD",
            value: getProductPrice(dwData["products"]),
            items: items,
        } }));
    dataLayerPushEcommerce(donateWidgetEventData);
};
var paymentViewHandler = function (e) {
    var dwData = Object.assign({}, normalizeObject(e === null || e === void 0 ? void 0 : e.detail));
    var parsedInputData = parseInputData(dwData);
    var items = mapProductsToItems(dwData["products"]);
    var donateWidgetEventData = normalizeObject(__assign(__assign({}, parsedInputData), { event: "add_payment_info", step_number: 2, ecommerce: {
            items: items,
        } }));
    dataLayerPushEcommerce(donateWidgetEventData);
};
var billingViewHandler = function (e) {
    var dwData = Object.assign({}, normalizeObject(e === null || e === void 0 ? void 0 : e.detail));
    var parsedInputData = parseInputData(dwData);
    var items = mapProductsToItems(dwData["products"]);
    var donateWidgetEventData = normalizeObject(__assign(__assign({}, parsedInputData), { event: "add_billing_address", event_action: "checkout_donate_widget_billing_view", step_number: 3, ecommerce: {
            items: items,
        } }));
    dataLayerPushEcommerce(donateWidgetEventData);
};
var receiptViewHandler = function (e) {
    var dwData = Object.assign({}, (e === null || e === void 0 ? void 0 : e.detail));
    var parsedInputData = parseInputData(dwData);
    var items = mapProductsToItems(dwData["products"]);
    var site_transaction_id = dwData.site_transaction_id, cover_fee_amount = dwData.cover_fee_amount, cover_fee_selected = dwData.cover_fee_selected, payment_method_option = dwData.payment_method_option, email = dwData.email, total = dwData.total;
    var widgetEnv = new URLSearchParams(window.location.search).get("widgetenv") || "prod";
    (function () { return __awaiter(void 0, void 0, void 0, function () {
        var eHash, donateWidgetEventData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, toEhashAsync(email)];
                case 1:
                    eHash = _a.sent();
                    donateWidgetEventData = normalizeObject(__assign(__assign({}, parsedInputData), { event: "purchase", event_label: "gift_catalog", event_action: "checkout_donate_widget_receipt_viewed", site_transaction_id: site_transaction_id || "", site_transaction_status: "pending", cover_fee_amount: cover_fee_amount || "", cover_fee_selected: cover_fee_selected || "", payment_method_option: payment_method_option || "", ehash: eHash, ecommerce: {
                            currency: "USD",
                            value: total,
                            transaction_id: "wvus-woo-".concat(widgetEnv, "-").concat(site_transaction_id),
                            items: items,
                        } }));
                    dataLayerPushEcommerce(donateWidgetEventData);
                    return [2 /*return*/];
            }
        });
    }); })();
};
var errorViewHandler = function (e) {
    var dwData = Object.assign({}, (e === null || e === void 0 ? void 0 : e.detail));
    var parsedInputData = parseInputData(dwData);
    var _a = parsedInputData.error_type, error_type = _a === void 0 ? "" : _a, _b = parsedInputData.error_message, error_message = _b === void 0 ? "" : _b, _c = parsedInputData.error_source_type, error_source_type = _c === void 0 ? "donate_widget" : _c;
    var donateWidgetEventData = normalizeObject({
        "event": "error_view",
        "event_action": "error_view",
        "event_label": error_type,
        "event_category": "dw_error",
        "error_message": error_message,
        "error_type": error_source_type,
        "event_errors": 1,
        "total_errors": 1,
        "non_interaction": true,
    });
    dataLayerPush(donateWidgetEventData);
};
var errorUserEntryClickHandler = function (e) {
    var dwData = Object.assign({}, (e === null || e === void 0 ? void 0 : e.detail));
    var parsedInputData = parseInputData(dwData);
    var _a = parsedInputData.error_type, error_type = _a === void 0 ? "" : _a;
    var donateWidgetEventData = normalizeObject({
        "event": "error",
        "event_action": "error_click",
        "event_label": error_type,
        "event_category": "dw_error",
        "non_interaction": false
    });
    dataLayerPush(donateWidgetEventData);
};
function mapProductsToItems(products) {
    if (products === void 0) { products = []; }
    var items = [];
    var product = products[0];
    items.push({
        item_brand: "gift_catalog",
        item_category: product["category"] || "general",
        item_id: product["id"],
        item_name: product["name"] || "",
        item_variant: product["variant"] || "",
        price: product["price"] || "",
    });
    return items;
}
function getProductName(products) {
    if (products === void 0) { products = []; }
    return products[0]["name"] || "";
}
function getProductPrice(products) {
    if (products === void 0) { products = []; }
    return products[0]["price"] || "";
}
function parseInputData(dwData) {
    if (dwData === void 0) { dwData = {}; }
    return {
        event_action: dwData["event_action"] || dwData["event"],
        event_label: dwData["page_url"],
        event_category: "ecommerce",
        checkout_type: "donate_widget",
        page_location: dwData["page_url"] || "",
        page_path: dwData["page_path"] || "",
        page_title: dwData["page_name"] || "",
        site_language: "en-us",
        site_name: dwData["page_name"] || "",
        site_type: dwData["page_host"] || "",
        campaign: dwData["campaign"] || "",
        donate_widget_id: dwData["donate_widget_id"] || "",
        donate_widget_checkout_type: "".concat(dwData["donate_widget_checkout_type"], ":donate_widget"),
        error_type: dwData["error_type"] || "",
        error_message: dwData["error_message"] || "",
        error_source_type: dwData["error_source_type"] || "",
        non_interaction: false
    };
}
export { dwLoadEventHandler, dwGiveNowClickHandlere, monthlyUpsellClickHandler, paymentOptionsViewHandler, paymentViewHandler, billingViewHandler, receiptViewHandler, errorViewHandler, errorUserEntryClickHandler };
