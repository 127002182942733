import { dwLoadEventHandler, dwGiveNowClickHandlere, monthlyUpsellClickHandler, paymentOptionsViewHandler, paymentViewHandler, billingViewHandler, receiptViewHandler, errorViewHandler, errorUserEntryClickHandler } from "./DonateWidget";
(function (global) {
    // Donate Widget 
    global.document.addEventListener("donate_widget_loaded", dwLoadEventHandler);
    global.document.addEventListener("checkout_donate_widget_give_now_click", dwGiveNowClickHandlere);
    global.document.addEventListener("checkout_donate_widget_monthly_upsell", monthlyUpsellClickHandler);
    global.document.addEventListener("checkout_donate_widget_payment_options_view", paymentOptionsViewHandler);
    global.document.addEventListener("checkout_donate_widget_payment_view", paymentViewHandler);
    global.document.addEventListener("checkout_donate_widget_personal_info_submit_click", billingViewHandler);
    global.document.addEventListener("donate_widget_receipt_viewed", receiptViewHandler);
    global.document.addEventListener("error_view", errorViewHandler);
    global.document.addEventListener("error_user_entry_click", errorUserEntryClickHandler);
})(window);
